function seperateStyleFromInnerHtml(htmlStr: string) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlStr, 'text/html');
  const styleElements = doc.querySelectorAll('style');
  let styles = '';
  styleElements.forEach((style) => {
    styles += style.outerHTML || '';
    style.remove();
  });

  const content = doc.body.innerHTML;
  return { styles, content };
}

export default seperateStyleFromInnerHtml;
